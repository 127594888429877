'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.dispatch.controller:DispatchIntercadCtrl

 # @description

###
class DispatchIntercadCtrl
  ### @ngInject ###
  constructor:(
    FileSaver,
    IntercadMessages,
    RestUtils,
    $log
  ) ->
    @ctrlName = 'DispatchIntercadCtrl'
    @startDate = new Date()
    @endDate = new Date()
    @incidentNumber = ''
    @braboNumber = ''
    @downloading = false

    @downloadXML = =>
      @downloading = true

      #UTC Date time
      startDate = @startDate
      startDate.setHours(0,0,0,0)
      startDate = startDate.toISOString()
      endDate = @endDate
      endDate.setHours(23,59,59,0)
      endDate = endDate.toISOString()
      filterArray = [
        'timestamp.event,GTE:dt ' + startDate,
        'timestamp.event,LTE:dt ' + endDate,
        'metadata.payload,NOT_NULL'
      ]
      if @braboNumber && @braboNumber.length > 0
        filterArray.push 'unit.id,' + @braboNumber
      if @incidentNumber && @incidentNumber.length > 0
        filterArray.push 'event.id,' + @incidentNumber
      params = {
        'filter[]': filterArray
      }
      RestUtils.getFullList IntercadMessages, params
        .then (response) =>
          @downloading = false
          XMLData = ''
          angular.forEach response, (data) ->
            XMLData += if data.metadata.payload then data.metadata.payload + '\n' else ''

          FileSaver
            .saveFromResponse null, 'intercad-messages-' + (new Date()).toISOString() + '.txt', 'text/plain', XMLData
      , (error) =>
        @downloading = false

angular
  .module('mundoAdmin.dispatch')
  .controller 'DispatchIntercadCtrl', DispatchIntercadCtrl
